import './App.css';
import HomePage from './HomePage';
///App Entry Point, all other functions and routes are run through here.
function App() {
  return (
    <HomePage/>
  );
}

export default App;
